import globals from "@/globals";
import styles from "@/styles/Footer.module.css";
import i18next, { t } from "i18next";
import { JSX } from "react";

function Footer(): JSX.Element {
	if (globals.isApp) {
		return <></>;
	}

	const showBeian =
		globals.isChinaTimeZone ||
		i18next.language === "zh-CN" ||
		navigator.language.endsWith("-CN");
	return (
		<div className={styles["footer-wrapper"]}>
			<footer className={styles["footer"]}>
				&copy; {new Date().getFullYear()}{" "}
				<a
					href={globals.home}
					target="_blank"
					rel="noreferrer"
				>
					{t("fuzhouRetieheSoftwareCoLtd")}
				</a>
				{showBeian && (
					<>
						<span className="text-divider"></span>
						<a
							href="https://beian.miit.gov.cn/"
							target="_blank"
							rel="noreferrer"
						>
							闽ICP备2021006730号-1
						</a>
						<span className="text-divider"></span>
						<a
							href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35010202001376"
							target="_blank"
							rel="noreferrer"
						>
							闽公网安备 35010202001376号
						</a>
					</>
				)}
			</footer>
		</div>
	);
}

export default Footer;
