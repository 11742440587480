import PopupShell from "@/components/PopupShell";
import Progress from "@/components/Progress";
import QrCode from "@/components/QrCode";
import SendAgreement from "@/components/SendAgreement";
import SendFileList from "@/components/SendFileList";
import SendIdProvider from "@/components/SendIdProvider";
import SendSuccess from "@/components/SendSuccess";
import SenderCodeInput from "@/components/SenderCodeInput";
import Slides from "@/components/Slides";
import TextArea from "@/components/TextArea";
import globals from "@/globals";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
	closePopup,
	setCurrentFile,
	setIsMyFilesButtonHighlighted,
	setPendingUploadFiles,
	setProgress,
	setSendPage,
	setSenderCode,
} from "@/redux/reducers/app";
import styles from "@/styles/SendPopup.module.css";
import { SendPage, TextAreaPage } from "@/types";
import { sleep } from "@/utils";
import { t } from "i18next";
import { JSX, useCallback, useEffect, useRef, useState } from "react";

interface SendPopupProps {
	isOpen: boolean;
}

function SendPopup({ isOpen }: SendPopupProps): JSX.Element {
	const dispatch = useAppDispatch();
	const sendPage = useAppSelector((state) => state.app.sendPage);
	const sendSuccessInfo = useAppSelector(
		(state) => state.app.sendSuccessInfo,
	);

	const [isBackward, setIsBackward] = useState<boolean>(false);
	const [isClosing, setIsClosing] = useState<boolean>(false);
	const [showCodeFloat, setShowCodeFloat] = useState<boolean>(false);

	const codeFloatRef = useRef<HTMLDivElement>(null);

	const backToPage = useCallback(
		(page: SendPage): void => {
			setIsBackward(true);
			window.setTimeout(() => {
				dispatch(setSendPage(page));
			}, 1);
			window.setTimeout(() => {
				setIsBackward(false);
			}, globals.ANIMATION_WAIT_TIME);
		},
		[dispatch],
	);

	const closeSendPopup = useCallback((): void => {
		if (sendPage === SendPage.QR_CODE) {
			backToPage(SendPage.SUCCESS);
			return;
		}
		if (sendPage === SendPage.SENDER_CODE) {
			backToPage(SendPage.ID_SELECTOR);
			return;
		}
		setIsClosing(true);
		window.setTimeout(() => {
			dispatch(closePopup("send"));
			dispatch(setCurrentFile({}));
			dispatch(setPendingUploadFiles([]));
			dispatch(setProgress({}));
			dispatch(setSenderCode(""));
			setIsClosing(false);
			globals.pendingUploadFiles = [];
			if (sendPage === SendPage.SUCCESS) {
				setShowCodeFloat(true);
			}
		}, globals.ANIMATION_WAIT_TIME);
	}, [backToPage, dispatch, sendPage]);

	const runCodeAnimation = useCallback(async (): Promise<void> => {
		if (!codeFloatRef.current) {
			return;
		}
		const floatStyle = codeFloatRef.current.style;
		const floatWidth = codeFloatRef.current.offsetWidth;
		floatStyle.right = `calc(50% - ${floatWidth / 2}px)`;
		await sleep(500);
		floatStyle.transform = "scale(.5)";
		await sleep(250);
		floatStyle.right = `${81.58 - (floatWidth * 0.5) / 2}px`;
		floatStyle.top = "2px";
		await sleep(1000);
		floatStyle.transform = "scale(0)";
		dispatch(setIsMyFilesButtonHighlighted(true));
		await sleep(500);
		floatStyle.opacity = "0";
		await sleep(250);
		setShowCodeFloat(false);
		await sleep(250);
		dispatch(setIsMyFilesButtonHighlighted(false));
	}, [dispatch]);

	useEffect(() => {
		if (!showCodeFloat) {
			return;
		}
		void runCodeAnimation();
	}, [runCodeAnimation, showCodeFloat]);

	return (
		<>
			<PopupShell
				isClosing={isClosing}
				isOpen={isOpen}
				onRequestClose={
					sendPage === SendPage.PROGRESS ? undefined : closeSendPopup
				}
			>
				<Slides
					currentPage={sendPage}
					isBackward={isBackward}
				>
					<SendFileList backToPage={backToPage} />
					<TextArea
						backToPage={backToPage}
						currentPage={TextAreaPage.SEND_TEXT}
					/>
					<SendAgreement closeSendPopup={closeSendPopup} />
					<SendIdProvider />
					<SenderCodeInput backToPage={backToPage} />
					<Progress title={t("uploading")} />
					<SendSuccess />
					<QrCode />
				</Slides>
			</PopupShell>
			{showCodeFloat && (
				<div
					className={styles["code-float"]}
					ref={codeFloatRef}
				>
					{sendSuccessInfo.code}
				</div>
			)}
		</>
	);
}

export default SendPopup;
