import { useAppSelector } from "@/redux/hooks";
import styles from "@/styles/Progress.module.css";
import { getHelp, getRandomInteger } from "@/utils";
import clsx from "clsx";
import { t } from "i18next";
import { JSX, useEffect, useState } from "react";

interface ProgressProps {
	title: string;
}

function Progress({ title }: ProgressProps): JSX.Element {
	const progress = useAppSelector((state) => state.app.progress);

	const providedPercent = progress.percent || 0;

	const [percentOverride, setPercentOverride] =
		useState<number>(providedPercent);

	const percent = Math.round(
		Math.max(percentOverride, providedPercent) * 100,
	);

	useEffect(() => {
		// needs denominator to predict progress
		if (!progress.denominator || progress.percent === undefined) {
			return;
		}
		let delay: number;
		if (percentOverride > 0.7) {
			delay = getRandomInteger(60_000, 3_600_000);
		} else if (percentOverride > 0.6) {
			delay = 60_000;
		} else if (percentOverride > 0.4) {
			delay = getRandomInteger(10_000, 60_000);
		} else if (percentOverride > 0.2) {
			delay = getRandomInteger(2_000, 10_000);
		} else {
			delay = 750;
		}
		const intervalId = window.setTimeout(() => {
			if (!progress.denominator || progress.percent === undefined) {
				return;
			}
			if (progress.percent > percentOverride) {
				setPercentOverride(progress.percent);
				return;
			}
			const nextPercent = progress.percent + 1 / progress.denominator;
			const increasedPercent = percentOverride + 0.01;
			if (
				increasedPercent > 0.8 &&
				(increasedPercent >= nextPercent || increasedPercent >= 0.99)
			) {
				return;
			}
			setPercentOverride(increasedPercent);
		}, delay);
		return (): void => {
			window.clearInterval(intervalId);
		};
	}, [percentOverride, progress.denominator, progress.percent]);

	useEffect(() => {
		document.title = `[${percent}%] ${t("airportalTitle")}`;
		return (): void => {
			document.title = t("airportalTitle");
		};
	}, [percent]);

	if (progress.percent === undefined) {
		return <></>;
	}
	return (
		<>
			<div className={clsx("universal-container", styles["container"])}>
				<div className={clsx("popup-title", styles["popup-title"])}>
					{percent >= 99 ? t("almostThere") : title}
				</div>
				<div className={styles["description"]}>{progress.filename}</div>
				<div className={styles["progress-label"]}>
					{percent}
					<span>%</span>
				</div>
				<div
					className={clsx(
						styles["progress-bar"],
						styles["progress-bar-background"],
					)}
				>
					<div
						className={clsx(
							styles["progress-bar"],
							styles["progress-bar-foreground"],
						)}
						role="progressbar"
						style={{ width: percent + "%" }}
						aria-valuenow={percent}
						aria-valuemin={0}
						aria-valuemax={100}
					>
						<div className={styles["progress-bar-highlight"]}></div>
					</div>
				</div>
			</div>
			{progress.isReturnPremiumNoticeShown && (
				<button
					className={styles["description"]}
					onClick={getHelp}
					type="button"
				>
					{t("ifTransferFailsClickHereToReturnPremiumData")}
				</button>
			)}
		</>
	);
}

export default Progress;
