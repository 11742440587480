import SettingsItem from "@/components/SettingsItem";
import WifiTransferButtons from "@/components/WifiTransferButtons";
import * as desktopApp from "@/desktop-app";
import { useAppSelector } from "@/redux/hooks";
import { setWifiTransferPage } from "@/redux/reducers/app";
import styles from "@/styles/WifiTransferDashboard.module.css";
import { MenuItemInfo, WifiTransferPage } from "@/types";
import { showDialog } from "@/utils";
import {
	faCloudArrowDown,
	faEnvelope,
	faFile,
} from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import { JSX, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

interface WifiTransferDashboardProps {
	isClipboardSyncEnabled: boolean;
	updateIsClipboardSyncEnabled: (newValue: boolean) => void;
}

function WifiTransferDashboard({
	isClipboardSyncEnabled,
	updateIsClipboardSyncEnabled,
}: WifiTransferDashboardProps): JSX.Element {
	const dispatch = useDispatch();
	const wifiTransferServerIp = useAppSelector(
		(state) => state.app.wifiTransferServerIp,
	);

	const [localIp, setLocalIp] = useState<string>("");
	const [wifiName, setWifiName] = useState<string>("");

	const checkPrerequisites = (): boolean => {
		if (wifiTransferServerIp) {
			return desktopApp.isWifiTransferClientApproved(dispatch);
		} else {
			return desktopApp.hasWifiTransferClients(dispatch);
		}
	};

	const buttons: readonly MenuItemInfo[] = [
		{
			hasPopup: true,
			icon: faFile,
			label: t("sendFile"),
			onClick: (): void => {
				if (!checkPrerequisites()) {
					return;
				}
				desktopApp.sendFilesLocally(
					wifiTransferServerIp ? [wifiTransferServerIp] : undefined,
				);
			},
			when: true,
		},
		{
			hasPopup: false,
			icon: faEnvelope,
			label: t("sendText"),
			onClick: (): void => {
				if (!checkPrerequisites()) {
					return;
				}
				dispatch(setWifiTransferPage(WifiTransferPage.SEND_TEXT));
			},
			when: true,
		},
		{
			hasPopup: true,
			icon: faCloudArrowDown,
			label: t("receiveFile"),
			onClick: (): void => {
				if (!checkPrerequisites()) {
					return;
				}
				desktopApp.receiveFilesLocally();
				void showDialog(dispatch, t("selectFileOnAnotherDevice"));
			},
			when: true,
		},
	];

	const handleSyncClipboardChange = (newValue: boolean): void => {
		if (newValue) {
			if (!checkPrerequisites()) {
				return;
			}
			desktopApp.startClipboardSync();
		} else {
			desktopApp.stopClipboardSync();
		}
		updateIsClipboardSyncEnabled(newValue);
	};

	useEffect(() => {
		setLocalIp(desktopApp.getLocalIp());
		desktopApp
			.getWifiName()
			.then((newWifiName) => {
				setWifiName(newWifiName);
			})
			.catch(console.error);
	}, []);

	return (
		<>
			<h1 className="popup-title">{t("wifiTransfer")}</h1>
			<div className={styles["info-container"]}>
				<div>
					{t("localIp")}
					<span>{localIp}</span>
				</div>
				<div>
					{t("currentWifi")}
					<span>{wifiName || t("unknown")}</span>
				</div>
				{wifiTransferServerIp && (
					<div>
						{t("joined")}
						<span>{wifiTransferServerIp}</span>
					</div>
				)}
			</div>
			<WifiTransferButtons buttons={buttons} />
			<SettingsItem
				checked={isClipboardSyncEnabled}
				label={t("syncClipboard")}
				setChecked={handleSyncClipboardChange}
				when={true}
			/>
		</>
	);
}

export default WifiTransferDashboard;
