import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setPassword } from "@/redux/reducers/app";
import styles from "@/styles/ReceiveCodeInput.module.css";
import clsx from "clsx";
import { t } from "i18next";
import { ChangeEvent, FormEvent, JSX, RefObject, useId } from "react";

interface ReceivePasswordInputProps {
	inputRef: RefObject<HTMLInputElement | null>;
	receiveFile: (code: string) => void;
}

function ReceivePasswordInput({
	inputRef,
	receiveFile,
}: ReceivePasswordInputProps): JSX.Element {
	const dispatch = useAppDispatch();

	const code = useAppSelector((state) => state.app.code);
	const password = useAppSelector((state) => state.app.password);

	const id = useId();

	const handleCodeChange = (event: ChangeEvent<HTMLInputElement>): void => {
		dispatch(setPassword(event.target.value));
	};

	const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
		event.preventDefault();
		if (password) {
			receiveFile(code);
		} else {
			inputRef.current?.focus();
		}
	};

	return (
		<form
			className="universal-container"
			onSubmit={handleSubmit}
		>
			<div className="popup-title">
				<label htmlFor={id}>{t("enterPassword")}</label>
				<div className="tip">{t("uploaderOfThisFileSetPassword")}</div>
			</div>
			<div>
				<input
					className={clsx("input", styles["receive-input"])}
					data-autofocus
					data-testid="password-input"
					id={id}
					ref={inputRef}
					autoComplete="off"
					value={password}
					onChange={handleCodeChange}
				/>
			</div>
			<button
				className="popup-main-button"
				type="submit"
			>
				{t("ok")}
			</button>
		</form>
	);
}

export default ReceivePasswordInput;
