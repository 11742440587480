import PopupShell from "@/components/PopupShell";
import Slides from "@/components/Slides";
import TextArea from "@/components/TextArea";
import WifiTransferDashboard from "@/components/WifiTransferDashboard";
import WifiTransferDeviceList from "@/components/WifiTransferDeviceList";
import WifiTransferMode from "@/components/WifiTransferMode";
import * as desktopApp from "@/desktop-app";
import globals from "@/globals";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
	closePopup,
	setCurrentFile,
	setToast,
	setWifiTransferPage,
	setWifiTransferServerIp,
} from "@/redux/reducers/app";
import { TextAreaPage, WifiTransferPage } from "@/types";
import { t } from "i18next";
import { JSX, useCallback, useState } from "react";

interface WifiTransferPopupProps {
	isOpen: boolean;
}

function WifiTransferPopup({ isOpen }: WifiTransferPopupProps): JSX.Element {
	const dispatch = useAppDispatch();
	const wifiTransferPage = useAppSelector(
		(state) => state.app.wifiTransferPage,
	);

	const [isBackward, setIsBackward] = useState<boolean>(false);
	const [isClipboardSyncEnabled, setIsClipboardSyncEnabled] =
		useState<boolean>(desktopApp.isClipboardSyncEnabled());
	const [isClosing, setIsClosing] = useState<boolean>(false);

	const backToPage = useCallback(
		(page: WifiTransferPage): void => {
			setIsBackward(true);
			window.setTimeout(() => {
				dispatch(setWifiTransferPage(page));
			}, 1);
			window.setTimeout(() => {
				setIsBackward(false);
			}, globals.ANIMATION_WAIT_TIME);
		},
		[dispatch],
	);

	const closeWifiTransferPopup = (): void => {
		if (wifiTransferPage > WifiTransferPage.DASHBOARD) {
			dispatch(setCurrentFile({}));
			backToPage(WifiTransferPage.DASHBOARD);
			return;
		}
		setIsClosing(true);
		window.setTimeout(() => {
			dispatch(setWifiTransferServerIp(""));
			dispatch(closePopup("wifiTransfer"));
			setIsClosing(false);
			if (desktopApp.closeWifiTransfer()) {
				dispatch(
					setToast({
						text: t("wifiTransferStopped"),
					}),
				);
			}
		}, globals.ANIMATION_WAIT_TIME);
	};

	const updateIsClipboardSyncEnabled = (newValue: boolean): void => {
		setIsClipboardSyncEnabled(newValue);
	};

	return (
		<PopupShell
			isClosing={isClosing}
			isOpen={isOpen}
			onRequestClose={closeWifiTransferPopup}
		>
			<Slides
				currentPage={wifiTransferPage}
				isBackward={isBackward}
			>
				<WifiTransferMode
					updateIsClipboardSyncEnabled={updateIsClipboardSyncEnabled}
				/>
				<WifiTransferDeviceList
					closeWifiTransferPopup={closeWifiTransferPopup}
					updateIsClipboardSyncEnabled={updateIsClipboardSyncEnabled}
				/>
				<WifiTransferDashboard
					isClipboardSyncEnabled={isClipboardSyncEnabled}
					updateIsClipboardSyncEnabled={updateIsClipboardSyncEnabled}
				/>
				<TextArea
					backToPage={backToPage}
					currentPage={TextAreaPage.SEND_TEXT_LOCALLY}
				/>
				<TextArea
					backToPage={backToPage}
					currentPage={TextAreaPage.TEXT_RECEIVED_LOCALLY}
				/>
			</Slides>
		</PopupShell>
	);
}

export default WifiTransferPopup;
