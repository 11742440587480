import WifiTransferButtons from "@/components/WifiTransferButtons";
import * as desktopApp from "@/desktop-app";
import { useAppDispatch } from "@/redux/hooks";
import { setWifiTransferPage } from "@/redux/reducers/app";
import styles from "@/styles/WifiTransferMode.module.css";
import { MenuItemInfo, WifiTransferPage } from "@/types";
import {
	faArrowRight,
	faPlus,
	faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { JSX } from "react";

interface WifiTransferModeProps {
	updateIsClipboardSyncEnabled: (newValue: boolean) => void;
}

function WifiTransferMode({
	updateIsClipboardSyncEnabled,
}: WifiTransferModeProps): JSX.Element {
	const dispatch = useAppDispatch();

	const buttons: readonly MenuItemInfo[] = [
		{
			hasPopup: false,
			icon: faPlus,
			label: t("create"),
			onClick: (): void => {
				desktopApp.startWifiTransferServer(
					dispatch,
					updateIsClipboardSyncEnabled,
				);
				dispatch(setWifiTransferPage(WifiTransferPage.DASHBOARD));
			},
			when: true,
		},
		{
			hasPopup: false,
			icon: faArrowRight,
			label: t("join"),
			onClick: (): void => {
				dispatch(setWifiTransferPage(WifiTransferPage.DEVICE_LIST));
			},
			when: true,
		},
	];

	return (
		<>
			<h1 className="popup-title">{t("wifiTransfer")}</h1>
			<WifiTransferButtons buttons={buttons} />
			<div className={styles["warning"]}>
				<FontAwesomeIcon icon={faTriangleExclamation} />
				{t("wifiTransferWarning")}
			</div>
		</>
	);
}

export default WifiTransferMode;
