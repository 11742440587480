import globals from "@/globals";
import mainLogo from "@/images/main-logo-2020.webp?asset";
import { JSX } from "react";

interface LogoBoxProps {
	className?: string;
}

function LogoBox({ className }: LogoBoxProps): JSX.Element {
	return (
		<picture className={className}>
			<source
				srcSet={
					globals.isApp
						? mainLogo
						: "https://assets.retiehe.com/ap-main-logo-4.webp"
				}
				type="image/webp"
			/>
			<img
				src="https://assets.retiehe.com/ap-main-logo-4.png"
				alt={globals.APP_NAME}
				draggable="false"
				width="144"
				height="159"
			/>
		</picture>
	);
}

export default LogoBox;
