import * as api from "@/api";
import LocalWallpaper from "@/classes/LocalWallpaper";
import Receiver from "@/classes/Receiver";
import AboutPopup from "@/components/AboutPopup";
import Analytics from "@/components/Analytics";
import ArticlePopup from "@/components/ArticlePopup";
import CaptchaIframe from "@/components/CaptchaIframe";
import CornerButtons from "@/components/CornerButtons";
import Footer from "@/components/Footer";
import LoadingScreen from "@/components/LoadingScreen";
import MainBox from "@/components/MainBox";
import MainMenu from "@/components/MainMenu";
import MessageDialog from "@/components/MessageDialog";
import NotificationFloat from "@/components/NotificationFloat";
import Posters from "@/components/Posters";
import ReceivePopup from "@/components/ReceivePopup";
import SendPopup from "@/components/SendPopup";
import SenderCodePopup from "@/components/SenderCodePopup";
import SettingsPopup from "@/components/SettingsPopup";
import TitleBar from "@/components/TitleBar";
import Toast from "@/components/Toast";
import TopWarning from "@/components/TopWarning";
import Wallpaper from "@/components/Wallpaper";
import WifiTransferPopup from "@/components/WifiTransferPopup";
import { importSemver, loadDependencies } from "@/dependencies";
import * as desktopApp from "@/desktop-app";
import globals from "@/globals";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
	closePopup,
	mergeSettings,
	openPopup,
	setCode,
	setCurrentFile,
	setIsCaptchaShown,
	setIsLoadingScreenShown,
	setIsMenuShown,
	setLinks,
	setLogin,
	setNotification,
	setPosters,
	setPremiumData,
	setReceivePage,
	setSelectedServer,
	setSendPage,
	setServers,
	setWallpaper,
} from "@/redux/reducers/app";
import { LoginInfo, RealIdLevel, ReceivePage, SendPage } from "@/types";
import {
	addFiles,
	clearPathname,
	encodeData,
	isMainland,
	loadJs,
	openAccount,
	replacePathname,
	showDialog,
	showResponseDialog,
	uninstallServiceWorker,
} from "@/utils";
import i18next, { t } from "i18next";
import md5 from "md5";
import { JSX, useCallback, useEffect } from "react";
import { registerSW } from "virtual:pwa-register";

function App(): JSX.Element {
	const dispatch = useAppDispatch();

	const arePopupsShown = useAppSelector((state) => state.app.arePopupsShown);
	const isCaptchaShown = useAppSelector((state) => state.app.isCaptchaShown);
	const isLoadingScreenShown = useAppSelector(
		(state) => state.app.isLoadingScreenShown,
	);
	const isMainBoxShown = useAppSelector((state) => state.app.isMainBoxShown);
	const isMenuShown = useAppSelector((state) => state.app.isMenuShown);
	const notification = useAppSelector((state) => state.app.notification);
	const premiumData = useAppSelector((state) => state.app.premiumData);
	const wifiTransferServerIp = useAppSelector(
		(state) => state.app.wifiTransferServerIp,
	);

	const areAnyPopupsShown = Object.values(arePopupsShown).some((value) => {
		return value;
	});

	const receiveFile = useCallback(
		(code: string): void => {
			if (Receiver.isCodeInvalid(code)) {
				return;
			}
			dispatch(setCode(code));
			dispatch(setReceivePage(ReceivePage.CODE));
			dispatch(openPopup("receive"));
			void new Receiver(dispatch, premiumData > 0).receiveFile(code);
		},
		[dispatch, premiumData],
	);

	const getRecentFile = useCallback(async (): Promise<void> => {
		try {
			if (!globals.login.username || notification.text) {
				return;
			}
			const data = await api.getRecentFile();
			if (!data) {
				return;
			}
			if (data.text) {
				const tipId = md5(data.text);
				if (globals.closedTips.has(tipId)) {
					return;
				}
				globals.notificationCallbacks.onClick = (): void => {
					dispatch(
						setCurrentFile({
							code: data.code,
							text: data.text,
						}),
					);
					dispatch(setReceivePage(ReceivePage.TEXT_RECEIVED));
					dispatch(openPopup("receive"));
				};
				globals.notificationCallbacks.onClose = (): void => {
					globals.closedTips.add(tipId);
				};
				dispatch(
					setNotification({
						text: decodeURIComponent(data.text),
						title: t("confirmReceiveTextNow"),
					}),
				);
			} else if (data.code) {
				const code = data.code.toString();
				if (globals.closedTips.has(code)) {
					return;
				}
				globals.notificationCallbacks.onClick = (): void => {
					receiveFile(code);
				};
				globals.notificationCallbacks.onClose = (): void => {
					globals.closedTips.add(code);
				};
				dispatch(
					setNotification({
						text: decodeURIComponent(data.name || code),
						title: t("confirmReceiveFileNow"),
					}),
				);
			}
		} catch (error) {
			api.handleApiError(dispatch, error);
		}
	}, [dispatch, notification.text, receiveFile]);

	const loggedIn = useCallback(
		async (isNewLogin: boolean): Promise<void> => {
			if (isNewLogin) {
				dispatch(closePopup("login"));
				if (desktopApp.isElectron) {
					desktopApp.currentWindow.restore();
				}
				document.getElementById("auth-opt-login-btn")?.click();
			}
			if (
				globals.isApp &&
				globals.login.token &&
				globals.login.username
			) {
				localStorage.setItem("Token", globals.login.token);
				localStorage.setItem("Username", globals.login.username);
			}
			try {
				const data = await api.getExpirationTime();
				if (data.data) {
					dispatch(setPremiumData(data.data));
				}
			} catch (error) {
				api.handleApiError(dispatch, error);
			}
			try {
				const data = await api.getSettings();
				dispatch(mergeSettings(data));
			} catch (error) {
				api.handleApiError(dispatch, error);
			}
			if (!globals.params.code) {
				if (!globals.isApp && isMainland()) {
					try {
						const data = await api.getProfile(["realId"]);
						if (
							data.realIdLevel &&
							data.realIdLevel < RealIdLevel.PHONE_VERIFIED
						) {
							await showDialog(
								dispatch,
								t("requirePhoneNumber"),
								{
									showCancel: true,
									title: t("realIdVerification"),
								},
							);
							openAccount("", {
								action: "changePhone",
							});
						}
					} catch (error) {
						api.handleApiError(dispatch, error);
					}
				}
				void getRecentFile();
			}
		},
		[dispatch, getRecentFile],
	);

	const getInit = useCallback(async () => {
		dispatch(setIsLoadingScreenShown(true));
		try {
			const data = await api.getInit();
			if (data.blockReason) {
				window.location.href =
					"https://account.retiehe.com/blocked?" +
					encodeData({
						reason: data.blockReason,
					});
				return;
			}
			if (data.redirectTo) {
				window.location.href = data.redirectTo;
				return;
			}
			globals.dynamicInfo = data;
			globals.dynamicInfo.loaded = true;
			if (data.alert) {
				void showResponseDialog(dispatch, data.alert, data.link);
			}
			if (data.gray) {
				document.documentElement.classList.add("gray");
			}
			if (data.latestVersion && globals.isApp) {
				importSemver()
					.then((semver) => {
						if (
							data.latestVersion &&
							semver.gt(data.latestVersion, globals.VERSION)
						) {
							showDialog(dispatch, t("confirmUpdate"), {
								showCancel: true,
							})
								.then(() => {
									window.open(
										data.appDlLink || globals.FRONTEND,
									);
								})
								.catch(console.error);
						}
					})
					.catch(console.error);
			}
			if (data.links) {
				dispatch(setLinks(data.links));
			}
			if (data.login) {
				try {
					const newLogin = JSON.parse(data.login) as LoginInfo;
					for (const key in newLogin) {
						const keyTyped = key as keyof LoginInfo;
						globals.login[keyTyped] = newLogin[keyTyped];
					}
					dispatch(setLogin(globals.login));
				} catch (error) {
					console.error(error);
				}
			} else if (!globals.isApp) {
				// The loading animation does not need to wait for this
				// response, so no await here.
				api.syncLoginInfo()
					.then((loginInfo) => {
						if (!loginInfo) {
							return;
						}
						window.postMessage(loginInfo, window.location.origin);
					})
					.catch((error) => {
						console.error(error);
					});
			}
			if (globals.login.username) {
				void loggedIn(false);
			} else {
				localStorage.removeItem("Token");
				localStorage.removeItem("Username");
				localStorage.removeItem("Wallpaper");
				document.documentElement.classList.remove("has-wallpaper");
				const localWallpaper = LocalWallpaper.getInstance(dispatch);
				void localWallpaper.deleteWallpaper();
			}
			if (data.posters && data.posters.length > 0) {
				dispatch(setPosters(data.posters));
			}
			if (data.selectedServer) {
				dispatch(setSelectedServer(data.selectedServer));
			}
			if (data.servers) {
				dispatch(setServers(data.servers));
			}
			if (
				data.sw !== undefined &&
				!globals.isApp &&
				process.env.NODE_ENV === "production" &&
				window.location.hostname.endsWith("airportal.cn")
			) {
				// Safari becomes white screen when updating PWA
				if (data.sw && !globals.isSafari) {
					registerSW();
				} else {
					void uninstallServiceWorker();
				}
			}
			if (isMainland() || desktopApp.isElectron) {
				void loadJs("https://turing.captcha.qcloud.com/TCaptcha.js");
			}
		} catch (error) {
			const lastUpdated = process.env.LAST_UPDATED;
			if (lastUpdated) {
				const expiredDate = new Date(lastUpdated);
				expiredDate.setFullYear(expiredDate.getFullYear() + 1);
				const expiredTimestamp = expiredDate.getTime();
				if (Date.now() > expiredTimestamp) {
					if (globals.isApp) {
						await showDialog(
							dispatch,
							t("endOfLife", {
								date: expiredDate.toLocaleDateString(
									i18next.language,
								),
							}),
						);
						window.open(globals.FRONTEND);
					} else {
						try {
							await uninstallServiceWorker();
						} catch (error) {
							console.error(error);
						}
						await showDialog(
							dispatch,
							t("essentialComponentNotLoaded"),
						);
						window.location.reload();
					}
					return;
				}
			}
			try {
				await api.checkConnectivity();
				void showDialog(dispatch, t("ispBlockedAirportal"));
			} catch (innerError) {
				console.error(innerError);
				api.handleApiError(dispatch, error);
			}
		} finally {
			dispatch(setIsLoadingScreenShown(false));
		}
	}, [dispatch, loggedIn]);

	useEffect(() => {
		const handleContextMenu = (event: MouseEvent): void => {
			const target = event.target as HTMLElement;
			if (!target) {
				return;
			}
			if (target === document.body) {
				event.preventDefault();
				dispatch(setIsMenuShown(true));
			}
		};
		window.addEventListener("contextmenu", handleContextMenu);
		return (): void => {
			window.removeEventListener("contextmenu", handleContextMenu);
		};
	}, [dispatch]);

	useEffect(() => {
		const handleDragOver = (event: DragEvent): void => {
			event.preventDefault();
		};
		window.addEventListener("dragover", handleDragOver);
		return (): void => {
			window.removeEventListener("dragover", handleDragOver);
		};
	}, []);

	useEffect(() => {
		const handleDrop = (event: DragEvent): void => {
			event.preventDefault();
			if (!event.dataTransfer) {
				return;
			}
			if (arePopupsShown.wifiTransfer) {
				const filePaths = [...event.dataTransfer.files].map((file) => {
					const path = desktopApp.getPathForFile(file);
					return path;
				});
				if (filePaths.length === 0) {
					return;
				}
				desktopApp.sendFilesLocally(
					wifiTransferServerIp ? [wifiTransferServerIp] : undefined,
					filePaths,
				);
			}
			if (areAnyPopupsShown) {
				return;
			}
			const files: File[] = [];
			if (
				typeof event.dataTransfer.items[0].webkitGetAsEntry ===
				"function"
			) {
				const readEntry = (
					entry: FileSystemEntry | null,
					isLast: boolean,
				): void => {
					if (!entry) {
						return;
					}
					if (entry.isDirectory) {
						const dirEntry = entry as FileSystemDirectoryEntry;
						dirEntry.createReader().readEntries((entries) => {
							for (let i = 0; i < entries.length; i++) {
								readEntry(
									entries[i],
									isLast && i === entries.length - 1,
								);
							}
						});
					} else {
						const fileEntry = entry as FileSystemFileEntry;
						fileEntry.file((file) => {
							if (file.size > 0 && !file.name.startsWith(".")) {
								files.push(file);
							}
							if (isLast) {
								addFiles(dispatch, files);
							}
						});
					}
				};
				for (let i = 0; i < event.dataTransfer.items.length; i++) {
					readEntry(
						event.dataTransfer.items[i].webkitGetAsEntry(),
						i === event.dataTransfer.items.length - 1,
					);
				}
			} else {
				for (let i = 0; i < event.dataTransfer.files.length; i++) {
					const file = event.dataTransfer.files[i];
					if (file.size > 0 && file.type) {
						files.push(file);
					}
				}
				addFiles(dispatch, files);
			}
		};
		window.addEventListener("drop", handleDrop);
		return (): void => {
			window.removeEventListener("drop", handleDrop);
		};
	}, [
		areAnyPopupsShown,
		arePopupsShown.wifiTransfer,
		dispatch,
		wifiTransferServerIp,
	]);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent): void => {
			if (event.ctrlKey || event.metaKey) {
				switch (event.code) {
					case "KeyI":
					case "KeyU": {
						if (process.env.NODE_ENV === "production") {
							event.preventDefault();
						}
						break;
					}
					default:
						break;
				}
			} else if (
				event.key === "F12" &&
				process.env.NODE_ENV === "production"
			) {
				event.preventDefault();
			} else if (
				!areAnyPopupsShown &&
				!isNaN(parseInt(event.key)) // uses .key to handle numpad
			) {
				dispatch(setReceivePage(ReceivePage.CODE));
				dispatch(openPopup("receive"));
			}
		};
		window.addEventListener("keydown", handleKeyDown);
		return (): void => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [areAnyPopupsShown, dispatch]);

	useEffect(() => {
		const handleMessage = (event: MessageEvent): void => {
			try {
				if (typeof event.data !== "string") {
					return;
				}
				const data = JSON.parse(
					decodeURIComponent(window.atob(event.data)),
				) as {
					action?: string;
					birthday?: string;
					email?: string;
					name?: string;
					phone?: string;
					text?: string;
					token?: string;
					username?: string;
					validate?: string;
				};
				switch (data.action) {
					case "alert": {
						if (data.text) {
							void showDialog(dispatch, data.text);
						}
						break;
					}
					case "captcha": {
						dispatch(setIsCaptchaShown(false));
						if (globals.captchaCallback) {
							globals.captchaCallback({
								ticket: data.validate,
							});
							globals.captchaCallback = null;
						}
						break;
					}
					case "login": {
						delete data.action;
						for (const key in data) {
							const keyTyped = key as keyof LoginInfo;
							globals.login[keyTyped] = data[keyTyped];
						}
						dispatch(setLogin(globals.login));
						void loggedIn(true);

						// save login info
						const sso = document.createElement("script");
						sso.src =
							globals.BACKEND +
							"sso?" +
							encodeData({
								token: globals.login.token,
								username: globals.login.username,
							});
						sso.addEventListener("load", () => {
							sso.remove();
						});
						document.body.appendChild(sso);
						break;
					}
					default:
						break;
				}
			} catch (error) {
				if (process.env.NODE_ENV === "development") {
					console.warn(event.data, error);
				}
			}
		};
		window.addEventListener("message", handleMessage);
		return (): void => {
			window.removeEventListener("message", handleMessage);
		};
	}, [dispatch, loggedIn]);

	useEffect(() => {
		const handlePaste = (event: ClipboardEvent): void => {
			if (
				event.target !== document.body ||
				!event.clipboardData ||
				!isMainBoxShown
			) {
				return;
			}
			const items = event.clipboardData.items;
			if (!items || items.length === 0) {
				return;
			}
			const files: File[] = [];
			for (let i = 0; i < items.length; i++) {
				if (items[i].kind === "file") {
					const file = items[i].getAsFile();
					if (file) {
						files.push(file);
					}
				}
			}
			if (files.length > 0) {
				addFiles(dispatch, files);
			} else if (items[0].kind === "string") {
				items[0].getAsString((text) => {
					if (!text) {
						return;
					}
					dispatch(
						setCurrentFile({
							text: text,
						}),
					);
					dispatch(setSendPage(SendPage.SEND_TEXT));
					dispatch(openPopup("send"));
				});
			}
		};
		document.body.addEventListener("paste", handlePaste);
		return (): void => {
			document.body.removeEventListener("paste", handlePaste);
		};
	}, [dispatch, isMainBoxShown]);

	useEffect(() => {
		const wallpaper = localStorage.getItem("Wallpaper");
		if (!wallpaper) {
			return;
		}
		if (wallpaper === "local") {
			const localWallpaper = LocalWallpaper.getInstance(dispatch);
			void localWallpaper.loadWallpaper();
		} else {
			document.documentElement.classList.add("has-wallpaper");
			dispatch(setWallpaper(wallpaper));
		}
	}, [dispatch]);

	useEffect(() => {
		const handleLocation = (): boolean => {
			if (
				!globals.isApp &&
				process.env.NODE_ENV === "production" &&
				!window.location.hostname.endsWith("airportal.cn")
			) {
				window.location.href = "https://www.airportal.cn/";
				return false;
			}
			return true;
		};

		if (globals.dynamicInfo.loaded || !handleLocation()) {
			return;
		}
		void getInit();

		if (!globals.isApp && window.location.pathname.length > 1) {
			if (window.location.pathname.endsWith("/")) {
				clearPathname();
			} else {
				const pathParts = window.location.pathname.split("/");
				if (pathParts.length <= 3) {
					const code = pathParts[1];
					globals.params.code = code;
					globals.params.key = decodeURIComponent(pathParts[2]).split(
						" ",
					)[0];
					receiveFile(code);

					// prevent re-downloading when refreshing
					replacePathname(window.location.pathname + "/");
				}
			}
		}
	}, [getInit, receiveFile]);

	useEffect(loadDependencies, []);

	return (
		<>
			<Wallpaper />
			{desktopApp.isElectron && <TitleBar />}
			{isMainBoxShown && <MainBox getRecentFile={getRecentFile} />}
			<TopWarning />
			<CornerButtons />
			<Posters />
			<Footer />
			<MainMenu isOpen={isMenuShown} />
			<AboutPopup isOpen={arePopupsShown.about} />
			<ArticlePopup isOpen={arePopupsShown.article} />
			<ReceivePopup isOpen={arePopupsShown.receive} />
			<SendPopup isOpen={arePopupsShown.send} />
			<SenderCodePopup
				getRecentFile={getRecentFile}
				isOpen={arePopupsShown.senderCode}
			/>
			<SettingsPopup isOpen={arePopupsShown.settings} />
			<WifiTransferPopup isOpen={arePopupsShown.wifiTransfer} />
			{isLoadingScreenShown && <LoadingScreen />}
			{isCaptchaShown && <CaptchaIframe />}
			<Toast />
			<NotificationFloat />
			<MessageDialog />
			<Analytics />
		</>
	);
}

export default App;
