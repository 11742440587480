import globals from "@/globals";
import { JSX, useEffect } from "react";

function Analytics(): JSX.Element {
	useEffect(() => {
		if (globals.isApp) {
			return;
		}
		window.setTimeout(() => {
			const stat = document.createElement("script");
			stat.async = true;
			stat.src =
				"https://hm.baidu.com/hm.js?496eb2308f8bb49d0b582a758b3c77a0";
			document.body.appendChild(stat);
		}, 1000);
	}, []);

	return <></>;
}

export default Analytics;
