import PopupShell from "@/components/PopupShell";
import globals from "@/globals";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { closePopup } from "@/redux/reducers/app";
import { JSX, useState } from "react";

interface ArticlePopupProps {
	isOpen: boolean;
}

function ArticlePopup({ isOpen }: ArticlePopupProps): JSX.Element {
	const dispatch = useAppDispatch();
	const article = useAppSelector((state) => state.app.article);

	const [isClosing, setIsClosing] = useState<boolean>(false);

	const closeArticlePopup = (): void => {
		setIsClosing(true);
		window.setTimeout(() => {
			dispatch(closePopup("article"));
			setIsClosing(false);
		}, globals.ANIMATION_WAIT_TIME);
	};

	let url = article.link;
	if (url && globals.isDark) {
		url = url + (url.includes("?") ? "&" : "?") + "theme=dark";
	}

	return (
		<PopupShell
			isClosing={isClosing}
			isOpen={isOpen}
			onRequestClose={closeArticlePopup}
		>
			<h1 className="popup-title">{article.alt}</h1>
			<iframe
				src={url}
				title={article.alt}
			></iframe>
		</PopupShell>
	);
}

export default ArticlePopup;
