import * as api from "@/api";
import Downloader from "@/classes/Downloader";
import * as desktopApp from "@/desktop-app";
import globals from "@/globals";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
	closePopup,
	increaseInvalidSenderCodeAttempts,
	setIsLoadingScreenShown,
	setReceivePage,
	setSendPage,
	setSendSuccessInfo,
	setToast,
} from "@/redux/reducers/app";
import styles from "@/styles/TextArea.module.css";
import { ReceivePage, SendPage, TextAreaPage, WifiTransferPage } from "@/types";
import {
	copyText,
	isMainland,
	logIn,
	showCaptcha,
	showDialog,
	showResponseDialog,
} from "@/utils";
import clsx from "clsx";
import { t } from "i18next";
import { JSX, useEffect, useId, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";

interface TextAreaProps {
	backToPage?: (page: number) => void;
	currentPage: TextAreaPage;
}

function TextArea({ backToPage, currentPage }: TextAreaProps): JSX.Element {
	const dispatch = useAppDispatch();

	const currentFile = useAppSelector((state) => state.app.currentFile);

	const id = useId();

	const defaultText = {
		[TextAreaPage.SEND_TEXT]: currentFile.text || "",
		[TextAreaPage.SEND_TEXT_LOCALLY]: currentFile.text || "",
		[TextAreaPage.TEXT_RECEIVED]: currentFile.text || "",
		[TextAreaPage.TEXT_RECEIVED_LOCALLY]: currentFile.text || "",
		[TextAreaPage.REPORT]: t("reportText", {
			code: currentFile.code || "",
		}),
	}[currentPage];

	const [inputValue, setInputValue] = useState<string>(defaultText);
	const [isLinkDangerous, setIsLinkDangerous] = useState<boolean>(false);
	const [warning, setWarning] = useState<string>(
		{
			[TextAreaPage.SEND_TEXT]: "",
			[TextAreaPage.SEND_TEXT_LOCALLY]: "",
			[TextAreaPage.TEXT_RECEIVED]: t("dlWarnText", {
				region: currentFile.region
					? renderToStaticMarkup(
							<>
								<span className="box">
									IP 属地{currentFile.region}
								</span>
								的
							</>,
						)
					: "",
			}),
			[TextAreaPage.TEXT_RECEIVED_LOCALLY]: "",
			[TextAreaPage.REPORT]: t("warnInvalidReport"),
		}[currentPage],
	);

	const textNoNewLines = currentFile.text
		? currentFile.text.replaceAll("\n", "")
		: "";
	const isLink = textNoNewLines
		? /^[a-z]+:\/\/[a-z0-9_\-/.#?=%]+$/i.test(textNoNewLines)
		: false;

	const title = {
		[TextAreaPage.SEND_TEXT]: t("sendText"),
		[TextAreaPage.SEND_TEXT_LOCALLY]: t("sendText"),
		[TextAreaPage.TEXT_RECEIVED]: t("textReceived"),
		[TextAreaPage.TEXT_RECEIVED_LOCALLY]: t("textReceived"),
		[TextAreaPage.REPORT]: t("report"),
	}[currentPage];

	let buttonText: string;
	if (currentPage === TextAreaPage.TEXT_RECEIVED) {
		if (isLink) {
			buttonText = t("open");
		} else if (globals.isApp || navigator.clipboard) {
			buttonText = t("copy");
		} else {
			buttonText = t("download");
		}
	} else {
		buttonText = t("send");
	}

	const handleTextAreaChange = (
		event: React.ChangeEvent<HTMLTextAreaElement>,
	): void => {
		setInputValue(event.target.value);
	};

	const handleButtonClick = (): void => {
		if (currentPage === TextAreaPage.TEXT_RECEIVED) {
			if (isLink) {
				// opens link
				if (isLinkDangerous) {
					void showDialog(dispatch, warning);
				} else {
					window.open(inputValue);
				}
			} else if (globals.isApp || navigator.clipboard) {
				void copyText(dispatch, inputValue);
			} else {
				// downloads text
				Downloader.downloadText(inputValue, t("textDocument") + ".txt");
			}
			return;
		}
		if (
			!inputValue ||
			(currentPage === TextAreaPage.REPORT && inputValue === defaultText)
		) {
			void showDialog(dispatch, t("enterText"));
			return;
		}
		if (currentPage === TextAreaPage.SEND_TEXT) {
			globals.sendCallback = sendText;
			dispatch(setSendPage(SendPage.AGREEMENT));
		} else if (currentPage === TextAreaPage.SEND_TEXT_LOCALLY) {
			if (globals.wifiTransferIp) {
				desktopApp.sendTextToServer(inputValue);
			} else {
				desktopApp.sendTextToClient(inputValue);
			}
			dispatch(
				setToast({
					text: t("textSent"),
				}),
			);
			backToPage?.(WifiTransferPage.DASHBOARD);
		} else if (currentPage === TextAreaPage.REPORT) {
			void sendReport();
		}
	};

	const sendReport = async (): Promise<void> => {
		dispatch(setIsLoadingScreenShown(true));
		try {
			await api.sendFeedback(inputValue, t("report"));
			void showDialog(dispatch, t("feedbackSentSuccessfully"));
			dispatch(closePopup("receive"));
		} catch (error) {
			api.handleApiError(dispatch, error);
		} finally {
			dispatch(setIsLoadingScreenShown(false));
		}
	};

	const sendText = async (senderCode?: string): Promise<void> => {
		const action = "sendText" + (senderCode ? "WithSenderCode" : "");
		if (!globals.login.username && isMainland() && !senderCode) {
			await showDialog(dispatch, t("requireLoginToSendFiles"));
			logIn();
			return;
		}
		const captchaResponse = await showCaptcha(dispatch, action);
		dispatch(setIsLoadingScreenShown(true));
		try {
			const data = await api.sendText(
				inputValue,
				captchaResponse,
				senderCode,
			);
			if (data.error) {
				if (
					data.error === "captchaRequired" &&
					!globals.captchaRequiredFor.has(action)
				) {
					globals.captchaRequiredFor.add(action);
					void sendText(senderCode);
				} else {
					if (data.error === "invalidSenderCode") {
						dispatch(increaseInvalidSenderCodeAttempts(1));
						data.alert = t("senderCodeInvalidOrExpired");
					}
					await showResponseDialog(
						dispatch,
						data.alert || data.error,
						data.link,
					);
					backToPage?.(SendPage.SEND_TEXT);
				}
			} else {
				dispatch(
					setSendSuccessInfo({
						code: data.code,
						key: data.key,
					}),
				);
				dispatch(setSendPage(SendPage.SUCCESS));
				if (data.fetch) {
					void fetch(data.fetch);
				}
			}
		} catch (error) {
			api.handleApiError(dispatch, error);
		} finally {
			dispatch(setIsLoadingScreenShown(false));
		}
	};

	useEffect(() => {
		if (!isLink || currentPage !== TextAreaPage.TEXT_RECEIVED) {
			return;
		}
		api.checkUrlSafety(textNoNewLines)
			.then((data) => {
				if (data.alert) {
					setIsLinkDangerous(true);
					setWarning(data.alert);
				}
			})
			.catch(console.error);
	}, [currentPage, isLink, textNoNewLines]);

	useEffect(() => {
		const reportLink = document.getElementById("report");
		if (reportLink) {
			reportLink.onclick = (event): void => {
				event.preventDefault();
				if (!globals.login.username) {
					logIn();
					return;
				}
				dispatch(setReceivePage(ReceivePage.REPORT));
			};
		}
	}, [dispatch, warning]);

	useEffect(() => {
		if (
			currentPage === TextAreaPage.TEXT_RECEIVED_LOCALLY &&
			currentFile.text
		) {
			setInputValue(currentFile.text);
		}
	}, [currentPage, currentFile.text]);

	return (
		<>
			<h1 className="popup-title">
				<label htmlFor={id}>{title}</label>
			</h1>
			{warning && (
				<div
					className={clsx("warning", styles["warning"])}
					dangerouslySetInnerHTML={{
						__html: warning,
					}}
				></div>
			)}
			<textarea
				className={clsx(
					styles["text-area"],
					!!warning && styles["has-warning"],
				)}
				data-autofocus={
					(currentPage === TextAreaPage.SEND_TEXT ||
						currentPage === TextAreaPage.SEND_TEXT_LOCALLY) &&
					!currentFile.text
				}
				id={id}
				placeholder={
					currentPage === TextAreaPage.TEXT_RECEIVED
						? ""
						: t("enterPlainTextHere")
				}
				value={inputValue}
				onChange={handleTextAreaChange}
			/>
			<button
				className="popup-main-button"
				type="button"
				onClick={handleButtonClick}
			>
				{buttonText}
			</button>
		</>
	);
}

export default TextArea;
