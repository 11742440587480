import globals from "@/globals";
import { useAppDispatch } from "@/redux/hooks";
import { openPopup, setSendPage } from "@/redux/reducers/app";
import styles from "@/styles/SendContextMenu.module.css";
import { SendPage } from "@/types";
import { handleKeyboardClick } from "@/utils";
import {
	faEnvelope,
	faFile,
	faFolderOpen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { t } from "i18next";
import { CSSProperties, JSX, MouseEvent, RefObject } from "react";

interface SendContextMenuProps {
	className?: string;
	fileInputRef: RefObject<HTMLInputElement | null>;
	style?: CSSProperties;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
	onRequestClose: () => void;
	sendFile: () => void;
}

function SendContextMenu({
	className,
	fileInputRef,
	style,
	onMouseEnter,
	onMouseLeave,
	onRequestClose,
	sendFile,
}: SendContextMenuProps): JSX.Element {
	const dispatch = useAppDispatch();

	const tips = [
		t("canDragAndDropToSend"),
		((): string => {
			let text = t("canPressCtrlVToSend");
			if (globals.isMac) {
				text = text.replace("Ctrl+", "⌘");
			}
			return text;
		})(),
	] as const;

	const tipElements = tips.map((tip) => {
		return <li key={tip}>{tip}</li>;
	});

	const handleContextMenu = (event: MouseEvent): void => {
		event.preventDefault();
	};

	const sendFolder = (): void => {
		if (!fileInputRef.current) {
			return;
		}
		fileInputRef.current.webkitdirectory = true;
		fileInputRef.current.click();
		onRequestClose();
	};

	const sendText = (): void => {
		dispatch(setSendPage(SendPage.SEND_TEXT));
		dispatch(openPopup("send"));
		onRequestClose();
	};

	return (
		<div
			className={clsx(
				styles["popup-menu"],
				className,
				!!style && styles["location-adjust"],
			)}
			style={style}
			onContextMenu={handleContextMenu}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			{!style && (
				<>
					<ul>{tipElements}</ul>
					<div className="line"></div>
				</>
			)}
			<div
				aria-haspopup="dialog"
				className={styles["send-type-button"]}
				role="button"
				tabIndex={0}
				onClick={sendFile}
				onKeyDown={handleKeyboardClick(sendFile)}
			>
				<FontAwesomeIcon icon={faFile} />
				{t("file")}
			</div>
			<div
				aria-haspopup="dialog"
				className={styles["send-type-button"]}
				role="button"
				tabIndex={0}
				onClick={sendText}
				onKeyDown={handleKeyboardClick(sendText)}
			>
				<FontAwesomeIcon icon={faEnvelope} />
				{t("text")}
			</div>
			<div
				aria-haspopup="dialog"
				className={styles["send-type-button"]}
				role="button"
				tabIndex={0}
				onClick={sendFolder}
				onKeyDown={handleKeyboardClick(sendFolder)}
			>
				<FontAwesomeIcon icon={faFolderOpen} />
				{t("folder")}
			</div>
		</div>
	);
}

export default SendContextMenu;
