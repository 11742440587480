import { useAppSelector } from "@/redux/hooks";
import styles from "@/styles/QrCode.module.css";
import { QRCodeSVG } from "qrcode.react";
import { JSX } from "react";

function QrCode(): JSX.Element {
	const qrCode = useAppSelector((state) => state.app.qrCode);

	return (
		<div className="universal-container">
			<div className={styles["qr-code"]}>
				<QRCodeSVG
					size={130}
					value={qrCode}
				/>
			</div>
		</div>
	);
}

export default QrCode;
