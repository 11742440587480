import globals from "@/globals";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setNotification } from "@/redux/reducers/app";
import styles from "@/styles/NotificationFloat.module.css";
import { handleKeyboardClick } from "@/utils";
import clsx from "clsx";
import { t } from "i18next";
import { JSX, MouseEvent } from "react";

function NotificationFloat(): JSX.Element {
	const dispatch = useAppDispatch();
	const notification = useAppSelector((state) => state.app.notification);

	const closeNotification = (event?: MouseEvent<HTMLButtonElement>): void => {
		event?.stopPropagation();
		globals.notificationCallbacks.onClose?.();
		globals.notificationCallbacks.onClose = null;
		globals.notificationCallbacks.onClick = null;
		dispatch(setNotification({}));
	};

	const handleClick = (): void => {
		globals.notificationCallbacks.onClick?.();
		closeNotification();
	};

	if (!notification.text || !notification.title) {
		return <></>;
	}
	return (
		<div
			className={clsx(styles["notification-float"], "fade-in")}
			role="button"
			tabIndex={0}
			onClick={handleClick}
			onKeyDown={handleKeyboardClick(handleClick)}
		>
			<div className={styles["icon"]}></div>
			<div className={styles["content"]}>
				<h1>{notification.title}</h1>
				<div className={styles["description"]}>
					{notification.text?.replace(/\r|\n/g, "")}
				</div>
			</div>
			<button
				type="button"
				onClick={closeNotification}
			>
				{t("close")}
			</button>
		</div>
	);
}

export default NotificationFloat;
