import * as api from "@/api";
import ActionButtonBar from "@/components/ActionButtonBar";
import Countdown from "@/components/Countdown";
import PopupShell from "@/components/PopupShell";
import globals from "@/globals";
import { useAppDispatch } from "@/redux/hooks";
import {
	closePopup,
	setIsLoadingScreenShown,
	setToast,
} from "@/redux/reducers/app";
import styles from "@/styles/SenderCodePopup.module.css";
import { copyText, showResponseDialog } from "@/utils";
import {
	faCopy,
	faRefresh,
	faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { t } from "i18next";
import { JSX, useCallback, useEffect, useState } from "react";

interface SenderCodePopupProps {
	getRecentFile: () => Promise<void>;
	isOpen: boolean;
}

function SenderCodePopup({
	getRecentFile,
	isOpen,
}: SenderCodePopupProps): JSX.Element {
	const PLACEHOLDER = "------";

	const dispatch = useAppDispatch();

	const [isClosing, setIsClosing] = useState<boolean>(false);
	const [senderCode, setSenderCode] = useState<string>(PLACEHOLDER);

	const closeSenderCodePopup = (): void => {
		api.deleteSenderCode()
			.then(() => {
				dispatch(
					setToast({
						text: t("senderCodeInvalidated"),
					}),
				);
			})
			.catch(console.error);
		void getRecentFile();
		setIsClosing(true);
		window.setTimeout(() => {
			dispatch(closePopup("senderCode"));
			setIsClosing(false);
		}, globals.ANIMATION_WAIT_TIME);
	};

	const createSenderCode = useCallback(async (): Promise<void> => {
		setSenderCode(PLACEHOLDER);
		dispatch(setIsLoadingScreenShown(true));
		try {
			const data = await api.createSenderCode();
			if (data.error) {
				void showResponseDialog(
					dispatch,
					data.alert || data.error,
					data.link,
				);
				return;
			} else if (data.code) {
				setSenderCode(data.code.toString());
			}
		} catch (error) {
			api.handleApiError(dispatch, error);
		} finally {
			dispatch(setIsLoadingScreenShown(false));
		}
	}, [dispatch]);

	const actionButtons = [
		{
			attributes: {
				onClick: (): void => {
					void copyText(dispatch, senderCode);
				},
			},
			icon: faCopy,
			label: t("copy"),
		},
		{
			attributes: {
				onClick: (): void => {
					void createSenderCode();
				},
			},
			icon: faRefresh,
			label: t("refresh"),
		},
	];

	useEffect(() => {
		if (!isOpen) {
			return;
		}
		void createSenderCode();
	}, [createSenderCode, isOpen]);

	return (
		<PopupShell
			countdown={
				<Countdown
					callback={closeSenderCodePopup}
					seconds={180}
					seed={senderCode}
				/>
			}
			isClosing={isClosing}
			isOpen={isOpen}
			onRequestClose={closeSenderCodePopup}
		>
			<div className="universal-container">
				<h1 className="popup-title">{t("yourSenderCode")}</h1>
				<div
					className={clsx("universal-container", styles["container"])}
				>
					<div className={clsx("code", "selectable", styles["code"])}>
						{senderCode}
					</div>
					<div className={clsx("tip", styles["tip"])}>
						{t("expiresAfterOneUse")}
					</div>
					<div className={clsx("warning", styles["warning"])}>
						<FontAwesomeIcon icon={faWarning} />
						{t("senderCodeWarning")}
					</div>
					<ActionButtonBar buttons={actionButtons} />
				</div>
			</div>
		</PopupShell>
	);
}

export default SenderCodePopup;
