import Greeting from "@/components/Greeting";
import globals from "@/globals";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
	openPopup,
	setIsMenuShown,
	setReceivePage,
} from "@/redux/reducers/app";
import styles from "@/styles/CornerButtons.module.css";
import { ReceivePage } from "@/types";
import { logIn } from "@/utils";
import { faBars, faFolder } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { t } from "i18next";
import { JSX } from "react";

function CornerButtons(): JSX.Element {
	const dispatch = useAppDispatch();

	const isMyFilesButtonHighlighted = useAppSelector(
		(state) => state.app.isMyFilesButtonHighlighted,
	);
	const login = useAppSelector((state) => state.app.login);

	const openMenu = (): void => {
		dispatch(setIsMenuShown(true));
	};

	const openMyFiles = (): void => {
		if (!globals.login.username) {
			logIn();
			return;
		}
		dispatch(setReceivePage(ReceivePage.MY_FILES));
		dispatch(openPopup("receive"));
	};

	return (
		<div className={styles["corner-buttons-container"]}>
			<Greeting />
			<button
				aria-haspopup={login.username ? "dialog" : undefined}
				className={clsx(
					styles["corner-button"],
					isMyFilesButtonHighlighted && styles["highlight"],
				)}
				title={t("myFiles")}
				type="button"
				onClick={openMyFiles}
			>
				<FontAwesomeIcon icon={faFolder} />
				{t("myFiles")}
			</button>
			<button
				aria-haspopup="menu"
				className={clsx(styles["corner-button"], styles["no-text"])}
				title={t("menu")}
				type="button"
				onClick={openMenu}
			>
				<FontAwesomeIcon icon={faBars} />
			</button>
		</div>
	);
}

export default CornerButtons;
