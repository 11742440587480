import styles from "@/styles/LivePhotoIcon.module.css";
import clsx from "clsx";
import { t } from "i18next";
import { JSX } from "react";

function LivePhotoIcon(): JSX.Element {
	return (
		<div
			className={styles["live-photo-icon"]}
			title={t("livePhoto")}
		>
			<div
				className={clsx(styles["circle"], styles["outer-circle"])}
			></div>
			<div
				className={clsx(styles["circle"], styles["middle-circle"])}
			></div>
			<div
				className={clsx(styles["circle"], styles["inner-circle"])}
			></div>
		</div>
	);
}

export default LivePhotoIcon;
