import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setArticle, setPosters } from "@/redux/reducers/app";
import styles from "@/styles/Posters.module.css";
import { handleKeyboardClick } from "@/utils";
import clsx from "clsx";
import { JSX } from "react";

function Posters(): JSX.Element {
	const dispatch = useAppDispatch();
	const posters = useAppSelector((state) => state.app.posters);

	if (posters.length === 0) {
		return <></>;
	}

	const posterElements = posters.map((poster) => {
		const handleClick = (): void => {
			if (poster.isArticle) {
				dispatch(setArticle(poster));
			} else {
				window.open(poster.link);
			}
			// removes the clicked poster from the list
			const newPosters = posters.filter((newPoster) => {
				return newPoster.src !== poster.src;
			});
			dispatch(setPosters(newPosters));
		};

		return (
			<div
				aria-haspopup={poster.isArticle ? "dialog" : undefined}
				key={poster.src}
				className={clsx(styles["poster"], "fade-in")}
				role="button"
				tabIndex={0}
				onClick={handleClick}
				onKeyDown={handleKeyboardClick(handleClick)}
			>
				<img
					src={poster.src}
					alt={poster.alt}
				/>
			</div>
		);
	});

	return <div className={styles["posters"]}>{posterElements}</div>;
}

export default Posters;
