import * as desktopApp from "@/desktop-app";
import globals from "@/globals";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
	setIsLoadingScreenShown,
	setWifiTransferPage,
	setWifiTransferServerIp,
} from "@/redux/reducers/app";
import styles from "@/styles/WifiTransferDeviceList.module.css";
import { WifiTransferPage } from "@/types";
import { handleKeyboardClick, showDialog, sleep } from "@/utils";
import { t } from "i18next";
import { JSX, useCallback, useEffect, useState } from "react";

interface WifiTransferDeviceListProps {
	closeWifiTransferPopup: () => void;
	updateIsClipboardSyncEnabled: (newValue: boolean) => void;
}

function WifiTransferDeviceList({
	closeWifiTransferPopup,
	updateIsClipboardSyncEnabled,
}: WifiTransferDeviceListProps): JSX.Element {
	const dispatch = useAppDispatch();
	const isLoadingScreenShown = useAppSelector(
		(state) => state.app.isLoadingScreenShown,
	);

	const [ips, setIps] = useState<string[]>([]);
	const [wifiName, setWifiName] = useState<string>("");

	const connectServer = async (
		ip: string,
		verifyCert: boolean,
	): Promise<void> => {
		try {
			dispatch(setIsLoadingScreenShown(true));
			const data = await desktopApp.requestWifiTransferServer({
				host: ip,
				method: "GET",
				path: "/hello.rth",
				timeout: 10000,
				verifyCert: verifyCert,
			});
			dispatch(setIsLoadingScreenShown(false));
			if (!data) {
				return;
			}
			const json = JSON.parse(data) as {
				isClient: boolean;
				isClipboardSyncSupported?: boolean;
				version: string;
				wifiTransferVersion?: string;
			};
			if (
				json.wifiTransferVersion !== process.env.WIFI_TRANSFER_VERSION
			) {
				void showDialog(dispatch, t("appVersionDifferent"));
				return;
			}
			if (json.isClient) {
				void showDialog(
					dispatch,
					t("selectedDeviceJoinedWifiTransferCreatedByAnotherDevice"),
				);
				return;
			}
			desktopApp.startWifiTransferClient(
				dispatch,
				ip,
				closeWifiTransferPopup,
				updateIsClipboardSyncEnabled,
			);
			dispatch(setWifiTransferServerIp(ip));
			dispatch(setWifiTransferPage(WifiTransferPage.DASHBOARD));
		} catch (error) {
			dispatch(setIsLoadingScreenShown(false));
			if (error instanceof Error) {
				if (error.message === "invalidCert") {
					await showDialog(
						dispatch,
						t("certificateAnomalyDetected"),
						{
							showCancel: true,
							title: t("danger"),
						},
					);
					await sleep(globals.ANIMATION_WAIT_TIME);
					void connectServer(ip, false);
				} else {
					void showDialog(dispatch, error.message, {
						title: t("error"),
					});
				}
			}
		}
	};

	const ipElements = ips.map((ip) => {
		const handleClick = (): void => {
			void connectServer(ip, true);
		};

		return (
			<div
				className="interactive"
				key={ip}
				role="button"
				tabIndex={0}
				onClick={handleClick}
				onKeyDown={handleKeyboardClick(handleClick)}
			>
				{ip}
			</div>
		);
	});

	const enterIpManually = async (): Promise<void> => {
		const ip = await showDialog(dispatch, t("enterServerIpAddress"), {
			isPrompt: true,
			showCancel: true,
		});
		await sleep(globals.ANIMATION_WAIT_TIME);
		if (ip) {
			const parts = ip.split(".");
			if (
				parts.length !== 4 ||
				parts.some((part) => {
					return (
						isNaN(Number(part)) ||
						Number(part) < 0 ||
						Number(part) > 255
					);
				})
			) {
				void showDialog(dispatch, t("invalidIpAddress"), {
					title: t("error"),
				});
				return;
			}
			void connectServer(ip, true);
		}
	};

	const handleOtherClick = (): void => {
		void enterIpManually();
	};

	const handleRefreshClick = (): void => {
		void loadIpList();
	};

	const loadIpList = useCallback(async (): Promise<void> => {
		try {
			dispatch(setIsLoadingScreenShown(true));
			const newIps = await desktopApp.scanNetwork();
			setIps(newIps);
		} catch (error) {
			console.error(error);
			if (error instanceof Error) {
				void showDialog(dispatch, error.message, {
					title: t("error"),
				});
			}
		} finally {
			dispatch(setIsLoadingScreenShown(false));
		}
	}, [dispatch]);

	useEffect(() => {
		desktopApp
			.getWifiName()
			.then((name) => {
				setWifiName(name);
			})
			.catch(console.error);
	}, []);

	useEffect(() => {
		void loadIpList();
	}, [loadIpList]);

	return (
		<>
			<h1 className="popup-title no-margin">{t("joinWifiTransfer")}</h1>
			<div className={styles["wifi-name"]}>{wifiName}</div>
			<div className="file-list">
				{ipElements}
				<div
					className="interactive"
					role="button"
					tabIndex={0}
					onClick={handleOtherClick}
					onKeyDown={handleKeyboardClick(handleOtherClick)}
				>
					{t("others")}
				</div>
			</div>
			<button
				className="popup-main-button"
				type="button"
				disabled={isLoadingScreenShown}
				onClick={handleRefreshClick}
			>
				{t("refresh")}
			</button>
		</>
	);
}

export default WifiTransferDeviceList;
