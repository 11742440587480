import styles from "@/styles/ActionButtonBar.module.css";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonHTMLAttributes, JSX } from "react";

interface ActionButtonBarProps {
	buttons: {
		attributes: ButtonHTMLAttributes<HTMLButtonElement>;
		icon: IconProp;
		label: string;
	}[];
}

function ActionButtonBar({ buttons }: ActionButtonBarProps): JSX.Element {
	const buttonElements = buttons.map((item) => {
		return (
			<button
				className={styles["action-button"]}
				key={item.label}
				type="button"
				{...item.attributes}
			>
				<FontAwesomeIcon icon={item.icon} />
				{item.label}
			</button>
		);
	});

	return <div className={styles["actions"]}>{buttonElements}</div>;
}

export default ActionButtonBar;
