import * as api from "@/api";
import LocalWallpaper from "@/classes/LocalWallpaper";
import PopupShell from "@/components/PopupShell";
import SettingsItem from "@/components/SettingsItem";
import * as desktopApp from "@/desktop-app";
import globals, { handleThemeChange } from "@/globals";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
	closePopup,
	mergeSettings,
	setIsLoadingScreenShown,
	setSelectedServer,
	setToast,
	setWallpaper,
} from "@/redux/reducers/app";
import { logIn, openAccount, showDialog, sleep } from "@/utils";
import i18next, { t } from "i18next";
import { ChangeEvent, JSX, useRef, useState } from "react";

interface SettingsPopupProps {
	isOpen: boolean;
}

function SettingsPopup({ isOpen }: SettingsPopupProps): JSX.Element {
	const dispatch = useAppDispatch();

	const premiumData = useAppSelector((state) => state.app.premiumData);
	const selectedServer = useAppSelector((state) => state.app.selectedServer);
	const servers = useAppSelector((state) => state.app.servers);
	const settings = useAppSelector((state) => state.app.settings);
	const wallpaper = useAppSelector((state) => state.app.wallpaper);

	const [downloadPath, setDownloadPath] = useState<string>(
		desktopApp.getDownloadPath(),
	);
	const [isClosing, setIsClosing] = useState<boolean>(false);
	const [isDark, setIsDark] = useState<boolean>(globals.isDark);
	const [language, setLanguage] = useState<string>(i18next.language);

	const wallpaperInputRef = useRef<HTMLInputElement>(null);

	const closeSettingsPopup = (): void => {
		setIsClosing(true);
		window.setTimeout(() => {
			dispatch(closePopup("settings"));
			setIsClosing(false);
		}, globals.ANIMATION_WAIT_TIME);
	};

	const handleWallpaperChange = (
		event: ChangeEvent<HTMLInputElement>,
	): void => {
		if (!event.target.files) {
			return;
		}
		const file = event.target.files[0];
		if (!file) {
			return;
		}
		const localWallpaper = LocalWallpaper.getInstance(dispatch);
		void localWallpaper.saveWallpaper(file);
	};

	const showSettingsSaved = (): void => {
		dispatch(
			setToast({
				text: t("settingsSaved"),
				timeout: 1500,
			}),
		);
	};

	const settingsItems = [
		{
			label: t("language"),
			options: [
				{
					label: "简体中文",
					value: "zh-CN",
				},
				{
					label: "繁體中文",
					value: "zh-TW",
				},
				{
					label: "English",
					value: "en-US",
				},
			],
			setValue: async (newValue: string): Promise<void> => {
				setLanguage(newValue);
				localStorage.setItem("Language", newValue);
				await showDialog(dispatch, t("refreshToApplySettings"), {
					showCancel: true,
				});
				window.location.reload();
			},
			value: language,
			when: true,
		},
		{
			label: t("server"),
			options: Object.keys(servers).map((key) => {
				return {
					label: servers[key].name,
					value: key,
				};
			}),
			setValue: async (newValue: string): Promise<void> => {
				if (!globals.login.username) {
					logIn();
					return;
				}
				if (premiumData <= 0) {
					await showDialog(
						dispatch,
						t("premiumRequiredForSwitchingServer"),
						{
							showCancel: true,
						},
					);
					openAccount("premium");
					return;
				}
				dispatch(setSelectedServer(newValue));
				globals.dynamicInfo.selectedServer = newValue;
			},
			value: selectedServer,
			when: true,
		},
		{
			checked: settings.loginRequired,
			label: t("requireLoginWhenReceivingMyFiles"),
			setChecked: async (newValue: boolean): Promise<void> => {
				if (!globals.login.username) {
					logIn();
					dispatch(
						mergeSettings({
							loginRequired: false,
						}),
					);
					return;
				}
				dispatch(
					mergeSettings({
						loginRequired: newValue,
					}),
				);
				dispatch(setIsLoadingScreenShown(true));
				try {
					await api.setSettings("loginRequired", newValue.toString());
					showSettingsSaved();
				} catch (error) {
					api.handleApiError(dispatch, error);
				} finally {
					dispatch(setIsLoadingScreenShown(false));
				}
			},
			when: true,
		},
		{
			checked: isDark,
			label: t("darkMode"),
			setChecked: (newValue: boolean): void => {
				setIsDark(newValue);
				globals.isDark = newValue;
				if (newValue) {
					localStorage.setItem("Theme", "Dark");
				} else {
					localStorage.setItem("Theme", "Light");
				}
				handleThemeChange();
				showSettingsSaved();
			},
			when: true,
		},
		{
			label: t("customWallpaper"),
			options: [
				{
					label: t("none"),
					value: "none",
				},
				{
					label: t("localImage"),
					value: "localImage",
				},
				{
					label: t("onlineImageUrl"),
					value: "onlineImageUrl",
					when: !globals.isApp,
				},
			],
			setValue: async (newValue: string): Promise<void> => {
				if (newValue !== "none") {
					if (!globals.login.username) {
						if (globals.isSafari) {
							await showDialog(
								dispatch,
								t("youHaveNotLoggedIn"),
								{
									showCancel: true,
								},
							);
						}
						logIn();
						return;
					}
					if (premiumData <= 0) {
						await showDialog(
							dispatch,
							t("premiumRequiredForUsingCustomWallpaper"),
							{
								showCancel: true,
							},
						);
						openAccount("premium");
						return;
					}
				}
				if (newValue === "localImage") {
					if (globals.isSafari) {
						await showDialog(dispatch, t("selectFile"));
					}
					wallpaperInputRef.current?.click();
					return;
				}
				if (newValue === "onlineImageUrl") {
					const url = await showDialog(
						dispatch,
						t("enterOnlineImageUrl"),
						{
							isPrompt: true,
							placeholder: "https://",
							showCancel: true,
						},
					);
					if (!url?.startsWith("https://")) {
						await sleep(globals.ANIMATION_WAIT_TIME * 2);
						void showDialog(dispatch, t("urlMustStartWithHttps"), {
							title: t("error"),
						});
						return;
					}
					localStorage.setItem("Wallpaper", url);
					document.documentElement.classList.add("has-wallpaper");
					dispatch(setWallpaper(url));
				} else {
					localStorage.removeItem("Wallpaper");
					document.documentElement.classList.remove("has-wallpaper");
					dispatch(setWallpaper(null));
				}
				const localWallpaper = LocalWallpaper.getInstance(dispatch);
				void localWallpaper.deleteWallpaper();
			},
			value: ((): string => {
				if (!wallpaper) {
					return "none";
				} else if (wallpaper.startsWith("blob:")) {
					return "localImage";
				} else {
					return "onlineImageUrl";
				}
			})(),
			when: true,
		},
		{
			button: t("change"),
			label: t("fileSaveLocation"),
			note: downloadPath,
			onClick: async (): Promise<void> => {
				const newPath = await desktopApp.selectDirectory();
				setDownloadPath(newPath);
				localStorage.setItem("DownloadLocation", newPath);
				showSettingsSaved();
			},
			when: desktopApp.isElectron,
		},
	];

	const settingsElements = settingsItems.map((item) => {
		return (
			<SettingsItem
				key={item.label}
				{...item}
			/>
		);
	});

	return (
		<PopupShell
			isClosing={isClosing}
			isOpen={isOpen}
			onRequestClose={closeSettingsPopup}
		>
			<div className="universal-container">
				<h1 className="popup-title">{t("settings")}</h1>
				<div className="content-left-aligned">{settingsElements}</div>
			</div>
			<input
				accept="image/*"
				hidden={true}
				onChange={handleWallpaperChange}
				ref={wallpaperInputRef}
				type="file"
			/>
		</PopupShell>
	);
}

export default SettingsPopup;
