import styles from "@/styles/LoadingScreen.module.css";
import clsx from "clsx";
import { JSX } from "react";

function LoadingScreen(): JSX.Element {
	return (
		<div className={clsx("overlay", styles["blur"])}>
			<div
				className={styles["loader"]}
				data-testid="loading"
			></div>
		</div>
	);
}

export default LoadingScreen;
