import Receiver from "@/classes/Receiver";
import Countdown from "@/components/Countdown";
import MyFiles from "@/components/MyFiles";
import PopupShell from "@/components/PopupShell";
import Progress from "@/components/Progress";
import ReceiveCodeInput from "@/components/ReceiveCodeInput";
import ReceiveFileList from "@/components/ReceiveFileList";
import ReceivePasswordInput from "@/components/ReceivePasswordInput";
import Slides from "@/components/Slides";
import TextArea from "@/components/TextArea";
import globals from "@/globals";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
	closePopup,
	setCode,
	setCurrentFile,
	setPassword,
	setProgress,
	setReceivePage,
	setReceivedFiles,
} from "@/redux/reducers/app";
import { ReceivePage, TextAreaPage } from "@/types";
import { clearPathname } from "@/utils";
import { t } from "i18next";
import { JSX, useCallback, useRef, useState } from "react";

interface ReceivePopupProps {
	isOpen: boolean;
}

function ReceivePopup({ isOpen }: ReceivePopupProps): JSX.Element {
	const dispatch = useAppDispatch();
	const password = useAppSelector((state) => state.app.password);
	const premiumData = useAppSelector((state) => state.app.premiumData);
	const receivePage = useAppSelector((state) => state.app.receivePage);

	const [isClosing, setIsClosing] = useState<boolean>(false);

	const codeInputRef = useRef<HTMLInputElement>(null);
	const passwordInputRef = useRef<HTMLInputElement>(null);

	const closeReceivePopup = useCallback((): void => {
		setIsClosing(true);
		window.setTimeout(() => {
			dispatch(closePopup("receive"));
			dispatch(setCode(""));
			dispatch(setCurrentFile({}));
			dispatch(setPassword(""));
			dispatch(setProgress({}));
			dispatch(setReceivedFiles([]));
			dispatch(setReceivePage(ReceivePage.CODE));
			setIsClosing(false);
		}, globals.ANIMATION_WAIT_TIME);
		clearPathname();
	}, [dispatch]);

	const receiveFile = (code: string): void => {
		void new Receiver(
			dispatch,
			premiumData > 0,
			codeInputRef,
			passwordInputRef,
		).receiveFile(code, password);
	};

	return (
		<PopupShell
			countdown={
				receivePage === ReceivePage.FILE_LIST ? (
					<Countdown
						callback={closeReceivePopup}
						seconds={120}
					/>
				) : undefined
			}
			isClosing={isClosing}
			isOpen={isOpen}
			onRequestClose={
				receivePage === ReceivePage.PROGRESS
					? undefined
					: closeReceivePopup
			}
		>
			<Slides
				currentPage={receivePage}
				isBackward={false}
			>
				<MyFiles receiveFile={receiveFile} />
				<ReceiveCodeInput
					inputRef={codeInputRef}
					receiveFile={receiveFile}
				/>
				<ReceivePasswordInput
					inputRef={passwordInputRef}
					receiveFile={receiveFile}
				/>
				<ReceiveFileList />
				<Progress title={t("downloading")} />
				<TextArea currentPage={TextAreaPage.TEXT_RECEIVED} />
				<TextArea currentPage={TextAreaPage.REPORT} />
			</Slides>
		</PopupShell>
	);
}

export default ReceivePopup;
